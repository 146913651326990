import * as React from 'react'
import { graphql } from 'gatsby'

import Cta from '../components/CTA'
import Expectations from '../components/Expectations'
import Faq from '../components/FAQ'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Screening from '../components/Screening'
import Seo from '../components/seo'
import Template from '../components/template'
import { Testimonials } from '../components/testimonials/testimonials'
import CTAMeet from '../components/CTAMeet'
import Interview from '../components/Interview'
import ModalVideoComponent from '../components/ModalVideo'

const IndexPage = ({ data }) => {
  const [isOpen, setOpen] = React.useState(false)
  const homePageData = data.contentfulHomePage
  return (
    <Layout>
      <Seo title='FaceFile: One way video interviews to screen candidates faster' />
      <Hero
        hero={homePageData.hero}
      />
      <Interview interview={homePageData.faceFileForVideoInterview} />
      <Testimonials testimonials={homePageData.testimonials} />
      <Template
        interviewSection={homePageData.interviewSection}
        eliminateTime={homePageData.eliminateTime}
        evaluateCandidatesSection={homePageData.evaluateCandidatesSection}
        screeningProcessSection={homePageData.screeningProcessSection}
      />
      <Screening faceFileWork={homePageData.faceFileWork} />
      <CTAMeet cta={homePageData.faceFileWork} />
      <Expectations
        simpleYetPowerfulSection={homePageData.simpleYetPowerfulSection}
      />
      <Faq
        title={homePageData.faqSection.title}
        faq={homePageData.faqSection.faqQa}
      />
      <Cta betterApplicants={homePageData.betterApplicants} setOpen={setOpen} />
      <ModalVideoComponent
        isOpen={isOpen}
        setOpen={setOpen}
        videoId={homePageData.hero.demoYoutubeVideoId}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    contentfulHomePage {
      hero {
        title
        description
        demoYoutubeVideoId
        freePlanButton {
          text
        }
        demoButton {
          text,
          link
        }
        noCardText
        image {
          url
        }
      }
      faceFileForVideoInterview {
        title
        description {
          description
        }
        reasons {
          title
          description
          learnMoreButton {
            text
            link
          }
          image {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
      testimonials {
        name
        designation
        review {
          review
        }
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      interviewSection {
        title
        description
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      eliminateTime {
        title
        description
        linkImage {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      evaluateCandidatesSection {
        title
        description
        getStarted {
          text
          link
        }
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      screeningProcessSection {
        title
        description
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      faceFileWork {
        title
        demoUrl
        demoYoutubeVideoId
        workSteps {
          title
          description
          image {
            url
          }
        }
        image {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
        paidPlans
        bestCandidateTitle
        evaluateDescription
        startFreeButton {
          text
        }
        viewPricing {
          text
        }
      }
      simpleYetPowerfulSection {
        title
        subtitle
        powerfulReasons {
          title
          description
          image {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
      faqSection {
        title
        faqQa {
          question
          answer {
            answer
          }
        }
      }
      betterApplicants {
        title
        demoYoutubeVideoId
        startForFreeButton {
          text
        }
        tryDemoButton {
          text
          link
        }
      }
    }
  }
`
