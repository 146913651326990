import { Box, Button, Container, Heading, Stack } from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'
import * as React from 'react'
import { actions } from './data'

const Cta = ({ betterApplicants, setOpen }) => {
  return (
    <Box as='section' bg='bg-surface'>
      <Container py={{ base: '12', lg: '16' }}>
        <Stack spacing={{ base: '8', lg: '10' }}>
          <Stack spacing={{ base: '4', lg: '5' }} align='center'>
            <Heading size={{ base: 'sm', lg: 'lg' }}>
              {betterApplicants.title}
            </Heading>
          </Stack>
          <Stack
            spacing='3'
            direction={{ base: 'column', sm: 'row' }}
            justify='center'
          >
            <Button
              variant='primary'
              size='lg'
              onClick={actions.handleOnboarding}
            >
              Get Started for Free
            </Button>
            <Button
              variant='secondary'
              size='lg'
              textDecoration='none'
              leftIcon={<FiExternalLink />}
              onClick={() => actions.handleDemo(betterApplicants.tryDemoButton.link)}
            >
              {betterApplicants.tryDemoButton.text}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Cta
