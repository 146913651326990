import React from 'react'
import {
  Box,
  HStack,
  SimpleGrid,
  Text,
  Flex
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Markdown from 'markdown-to-jsx'

export const Testimonials = ({ testimonials }) => {
  return (
    <Box backgroundColor='gray.50'>
      <Box
        w='100%'
        mx='auto'
        px={{
          base: '6',
          lg: '4'
        }}
        py={{ base: '24', lg: '20' }}
      >
        <SimpleGrid
          alignItems='flex-start'
          width='100%'
          columns={{
            base: 1,
            lg: 4
          }}
          spacing={{
            base: '20',
            lg: '8'
          }}
        >
          {testimonials.map(quote => (
            <Box key={quote.name} backgroundColor='white' h='2xs' borderRadius='lg' boxShadow='lg'>
              <HStack px='2'>
                <Box w='20' h='20' marginTop='-2.5rem'>
                  <GatsbyImage image={getImage(quote.image.gatsbyImageData)} alt={quote.name} />
                </Box>
                <Flex align='start' py='1' direction='column'>
                  <Text
                    fontWeight='bold'
                    fontSize='md'
                    color='gray.800'
                  >
                    {quote.name}
                  </Text>
                  <Text
                    color='gray.600'
                    fontSize='xs'
                    fontWeight={500}
                  >
                    {quote.designation}
                  </Text>
                </Flex>
              </HStack>
              <Box px='8' pt='4' pb='6'>
                <Text
                  fontSize='md'
                  color='gray.600'
                  fontWeight={400}
                >
                  <Markdown>
                    {quote.review.review}
                  </Markdown>
                </Text>
              </Box>
            </Box>
          ))}

        </SimpleGrid>
      </Box>
    </Box>
  )
}
