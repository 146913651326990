import { Box, Button, Heading, Text } from '@chakra-ui/react'
import * as React from 'react'
import { actions } from './data'

const CTAMeet = ({ cta }) => (
  <Box
    as='section'
    pt='24'
    pb='24'
    mt='24'
    mb='24'
    backgroundColor='#F7FAFC'
    margin='0px'
  >
    <Box
      maxW={{
        base: '2xl',
        md: '7xl'
      }}
      mx='auto'
      px={{
        base: '6',
        md: '8'
      }}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      className='cta-sm'
    >
      <Box>
        <Text color='blue.500'>
          <b>{cta.paidPlans}</b>
        </Text>
        <Heading size='sm' fontWeight='700'>
          {cta.bestCandidateTitle}
        </Heading>
        <Text color='muted' fontSize='18px'>
          {cta.evaluateDescription}
        </Text>
      </Box>
      <Box>
        <Button
          variant='primary'
          size='lg'
          marginRight='15px'
          onClick={actions.handleOnboarding}
        >
          {cta.startFreeButton.text}
        </Button>
        <Button variant='secondary' size='lg' onClick={actions.handlePricing}>
          {cta.viewPricing.text}
        </Button>
      </Box>
    </Box>
  </Box>
)

export default CTAMeet
