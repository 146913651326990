import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Square,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const Expectations = ({ simpleYetPowerfulSection }) => (
  <Box as='section' bg='blue.700' mt='0' mb='4'>
    <Container
      py={{
        base: '16',
        md: '24'
      }}
    >
      <Stack
        spacing={{
          base: '12',
          md: '16'
        }}
        alignItems='center'
      >
        <Stack
          spacing={{
            base: '4',
            md: '5'
          }}
          maxW='3xl'
        >
          <Stack spacing='3'>
            <Text
              fontSize={{
                base: 'sm',
                md: 'md'
              }}
              fontWeight='semibold'
              color='blue.500'
              textAlign='center'
              textTransform='uppercase'
            >
              {simpleYetPowerfulSection.subtitle}
            </Text>
            <Heading
              size={useBreakpointValue({
                base: 'sm',
                md: 'md'
              })}
              textAlign='center'
              color='white'
            >
              {simpleYetPowerfulSection.title}
            </Heading>
          </Stack>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3
          }}
          columnGap={8}
          rowGap={{
            base: 10,
            md: 16
          }}
        >
          {simpleYetPowerfulSection.powerfulReasons.map(feature => (
            <Stack
              key={feature.title}
              spacing={{
                base: '4',
                md: '5'
              }}
            >
              <Square
                size={{
                  base: '10',
                  md: '12'
                }}
                display='flex'
                justifyContent='flex-start'
              >
                <GatsbyImage
                  image={getImage(feature.image.gatsbyImageData)}
                  alt={feature.title}
                />
              </Square>
              <Stack
                spacing={{
                  base: '1',
                  md: '2'
                }}
                flex='1'
              >
                <Text
                  fontWeight='medium'
                  color='white'
                  marginTop='-20px'
                  fontSize={18}
                >
                  {feature.title}
                </Text>
                <Text fontWeight='light' color='white'>
                  {feature.description}
                </Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
)

export default Expectations
