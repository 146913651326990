import {
  Box,
  Flex,
  Heading,
  Image,
  Img,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'

import ModalVideoComponent from './ModalVideo'

const Screening = ({ faceFileWork }) => {
  const [isOpen, setOpen] = React.useState(false)

  const openVideoModal = () => {
    setOpen(true)
  }

  return (
    <>
      <ModalVideoComponent
        isOpen={isOpen}
        setOpen={setOpen}
        videoId={faceFileWork.demoYoutubeVideoId}
      />
      <Box
        as='section'
        bg={mode('gray.50', 'gray.800')}
        pt='24'
        pb='24'
        mt='24'
        mb='24'
        marginBottom={0}
      >
        <Box textAlign='center'>
          <Heading
            as='h2'
            size='3xl'
            color={mode('black.600', 'black.300')}
            fontWeight='bold'
            letterSpacing='tight'
            fontSize={'48px'}
          >
            {faceFileWork.title}
          </Heading>
        </Box>
        <Box
          maxW={{
            base: '2xl',
            md: '7xl'
          }}
          mx='auto'
          px={{
            base: '6',
            md: '8'
          }}
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'row'
            }}
            spacing={{
              base: '3rem',
              lg: '2rem'
            }}
            mt='8'
            align={{
              lg: 'center'
            }}
            justify='space-between'
          >
            <Box
              flex='1'
              maxW={{
                lg: '520px'
              }}
            >
              <Stack
                direction={{
                  base: 'column',
                  md: 'column'
                }}
                spacing='4'
                mt='8'
              >
                {faceFileWork.workSteps.map(step => (
                  <Box
                    width={{
                      base: '100%',
                      lg: '90%'
                    }}
                    key={step.title}
                  >
                    <Flex alignItems={'self-start'}>
                      <Box minWidth={'51px'}>
                        <Img src={step.image.url} mb='4' mr='4' />
                      </Box>
                      <div>
                        <Text fontWeight={'800'} mb='2' color={'blue.500'}>
                          {step.title}
                        </Text>
                        <Text>{step.description}</Text>
                      </div>
                    </Flex>
                  </Box>
                ))}
              </Stack>
            </Box>
            <Box
              pos='relative'
              _hover={{ cursor: 'pointer' }}
              w={{
                base: 'full',
                lg: '50%'
              }}
              h={{
                base: 'auto',
                lg: '100%'
              }}
              onClick={openVideoModal}
            >
              <Image
                src={`https://img.youtube.com/vi/${faceFileWork.demoYoutubeVideoId}/hq720.jpg`}
                alt={faceFileWork.title}
                w='100%'
                h='100%'
              />
              <Flex
                position='absolute'
                left={0}
                right={0}
                top={0}
                w='full'
                h='full'
                align='center'
                justify='center'
              >
                <GatsbyImage
                  image={getImage(faceFileWork.image.gatsbyImageData)}
                  alt={faceFileWork.title}
                />
              </Flex>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default Screening
