import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { actions } from './data'

const Template = ({
  interviewSection,
  eliminateTime,
  evaluateCandidatesSection,
  screeningProcessSection
}) => {
  return (
    <>
      <>
        <Box as='section' bg='white' pt='0' pb='4'>
          <Box
            maxW={{
              base: '2xl',
              md: '7xl'
            }}
            mx='auto'
            px={{
              base: '6',
              md: '8'
            }}
            className='template-section'
          >
            <Stack
              direction={{
                base: 'column',
                lg: 'row'
              }}
              spacing={{
                base: '3rem',
                lg: '2rem'
              }}
              mt='24'
              mb='8'
              align={{
                lg: 'center'
              }}
              justify='space-between'
              flexDirection='row'
              className='col_mob'
            >
              {' '}
              <Box
                pos='relative'
                w={{
                  base: 'full',
                  lg: '50%'
                }}
                h={{
                  base: 'auto',
                  lg: '100%'
                }}
              >
                <GatsbyImage
                  image={getImage(interviewSection.image.gatsbyImageData)}
                  alt={interviewSection.title}
                />
                <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
              </Box>
              <Box
                flex='1'
                maxW={{
                  lg: '520px'
                }}
              >
                <Heading
                  as='h2'
                  size={{
                    base: 'lg',
                    lg: 'xl'
                  }}
                  color={mode('black.600', 'black.300')}
                  fontWeight='bold'
                  letterSpacing='tight'
                  fontSize='48px'
                >
                  {interviewSection.title}
                </Heading>
                <Text
                  color={mode('gray.600', 'gray.400')}
                  mt='4'
                  fontSize='lg'
                  fontWeight='medium'
                >
                  {interviewSection.description}
                </Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      </>
      <Box as='section' bg='white' pt='0' pb='0'>
        <Box
          maxW={{
            base: '2xl',
            md: '7xl'
          }}
          mx='auto'
          px={{
            base: '6',
            md: '8'
          }}
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'row'
            }}
            spacing={{
              base: '3rem',
              lg: '2rem'
            }}
            mt='24'
            mb='8'
            align={{
              lg: 'center'
            }}
            justify='space-between'
            flexDirection='row-reverse'
            className='col_mob'
          >
            {' '}
            <Box
              pos='relative'
              w={{
                base: 'full',
                lg: '50%'
              }}
              h={{
                base: 'auto',
                lg: '100%'
              }}
            >
              <GatsbyImage
                image={getImage(eliminateTime.linkImage.gatsbyImageData)}
                alt={eliminateTime.title}
              />
              <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
            </Box>
            <Box
              flex='1'
              maxW={{
                lg: '520px'
              }}
            >
              <Heading
                as='h2'
                size={{
                  base: 'lg',
                  lg: 'lg'
                }}
                color={mode('black.600', 'black.300')}
                fontWeight='bold'
                letterSpacing='tight'
                fontSize='48px'
              >
                {eliminateTime.title}
              </Heading>
              <Text
                color={mode('gray.600', 'gray.400')}
                mt='4'
                fontSize='lg'
                fontWeight='medium'
              >
                {eliminateTime.description}
              </Text>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box as='section' bg='white' pt='0' pb='0'>
        <Box
          maxW={{
            base: '2xl',
            md: '7xl'
          }}
          mx='auto'
          px={{
            base: '6',
            md: '8'
          }}
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'row'
            }}
            spacing={{
              base: '3rem',
              lg: '2rem'
            }}
            mt='24'
            mb='8'
            align={{
              lg: 'center'
            }}
            justify='space-between'
            flexDirection='row'
            className='col_mob'
          >
            {' '}
            <Box
              pos='relative'
              w={{
                base: 'full',
                lg: '50%'
              }}
              h={{
                base: 'auto',
                lg: '100%'
              }}
            >
              <GatsbyImage
                image={getImage(
                  evaluateCandidatesSection.image.gatsbyImageData
                )}
                alt={evaluateCandidatesSection.title}
              />
              <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
            </Box>
            <Box
              flex='1'
              maxW={{
                lg: '520px'
              }}
            >
              <Heading
                as='h2'
                size={{
                  base: 'lg',
                  lg: 'lg'
                }}
                color={mode('black.600', 'black.300')}
                fontWeight='bold'
                letterSpacing='tight'
                fontSize='44px'
              >
                {evaluateCandidatesSection.title}
              </Heading>
              <Text
                color={mode('gray.600', 'gray.400')}
                mt='4'
                fontSize='lg'
                fontWeight='medium'
              >
                {evaluateCandidatesSection.description}
              </Text>
              <Stack
                direction={{
                  base: 'column',
                  md: 'row'
                }}
                spacing='4'
                mt='8'
              >
                <Button
                  size='lg'
                  colorScheme='blue'
                  height='14'
                  px='8'
                  shadow='base'
                  onClick={actions.handleOnboarding}
                >
                  {evaluateCandidatesSection.getStarted.text}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box as='section' bg='white' pt='0' pb='0'>
        <Box
          maxW={{
            base: '2xl',
            md: '7xl'
          }}
          mx='auto'
          px={{
            base: '6',
            md: '8'
          }}
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'row'
            }}
            spacing={{
              base: '3rem',
              lg: '2rem'
            }}
            mt='24'
            mb='8'
            align={{
              lg: 'center'
            }}
            justify='space-between'
            flexDirection='row-reverse'
            className='col_mob'
          >
            {' '}
            <Box
              pos='relative'
              w={{
                base: 'full',
                lg: '50%'
              }}
              h={{
                base: 'auto',
                lg: '100%'
              }}
            >
              <GatsbyImage
                image={getImage(screeningProcessSection.image.gatsbyImageData)}
                alt={screeningProcessSection.title}
              />
              <Box pos='absolute' w='100%' h='100%' top='-4' left='-4' />
            </Box>
            <Box
              flex='1'
              maxW={{
                lg: '520px'
              }}
            >
              <Heading
                as='h2'
                size={{
                  base: 'lg',
                  lg: 'lg'
                }}
                color={mode('black.600', 'black.300')}
                fontWeight='bold'
                letterSpacing='tight'
                fontSize='48px'
              >
                {screeningProcessSection.title}
              </Heading>
              <Text
                color={mode('gray.600', 'gray.400')}
                mt='4'
                fontSize='lg'
                fontWeight='medium'
              >
                {screeningProcessSection.description}
              </Text>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default Template
