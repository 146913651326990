import * as React from 'react'
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

const Interview = ({ interview }) => {
  return (
    <>
      <Box as='section' bg='white' pt='24' pb='10'>
        <Box
          maxW={{
            base: '2xl',
            md: '7xl'
          }}
          mx='auto'
          px={{
            base: '6',
            md: '8'
          }}
        >
          <Stack
            spacing={{
              base: '3rem',
              lg: '2rem'
            }}
            mt='8'
            align={{
              lg: 'center'
            }}
            justify='space-between'
          >
            <Box flex='1'>
              <Heading
                as='h1'
                size={{
                  base: 'lg',
                  lg: 'xl'
                }}
                color={mode('black.600', 'black.300')}
                fontWeight='bold'
                letterSpacing='tight'
                textAlign='center'
              >
                {interview.title}
              </Heading>
              <Text
                color={mode('gray.600', 'gray.400')}
                mt='4'
                fontSize='24px'
                fontWeight=''
                textAlign='center'
              >
                {interview.description.description}
              </Text>
            </Box>
            <Box
              display='flex'
              justifyContent='space-between'
              flexWrap='wrap'
            >
              {interview.reasons.map(reason => (
                <Box
                  flex='1'
                  backgroundColor='#FFF'
                  padding='30px'
                  borderRadius='10px'
                  margin='10px 10px'
                  minWidth='500px'
                  key={reason.title}
                >
                  <GatsbyImage
                    image={getImage(reason.image.gatsbyImageData)}
                    alt={reason.title}
                  />
                  <Heading
                    fontSize='18px'
                    fontWeight='bold'
                    lineHeight='28px'
                    paddingTop='20px'
                  >
                    {reason.title}
                  </Heading>
                  <Text
                    fontSize='16px'
                    fontWeight='400'
                    color='#4A5568'
                    lineHeight='24px'
                  >
                    {reason.description}
                  </Text>
                  <Button
                    variant='link'
                    fontSize='md'
                    color='blue.500'
                    colorScheme='blue'
                    paddingTop='2'
                    onClick={() => navigate(reason.learnMoreButton.link)}
                  >
                    {reason.learnMoreButton.text}
                  </Button>
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
export default Interview
