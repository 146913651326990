import * as React from 'react'
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Show,
  Hide,
  useColorModeValue as mode,
  Img
} from '@chakra-ui/react'
import { HiPlay } from 'react-icons/hi'
import { actions } from './data'

const Hero = ({
  hero
  // setOpen
}) => {
  // const openVideoModal = () => {
  //   setOpen(true)
  // }

  return (
    <Box as='section' bg='white' pt='24' pb='12'>
      <Box
        maxW={{
          base: '2xl',
          md: '7xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '8'
        }}
      >
        <Stack
          spacing={{
            base: '3rem',
            lg: '2rem'
          }}
          mt='8'
          align={{
            lg: 'center'
          }}
          justify='space-between'
        >
          <Box flex='1'>
            <Heading
              as='h1'
              size={{
                base: 'lg',
                lg: 'xl'
              }}
              className='hero-heading'
              color={mode('black.600', 'black.300')}
              fontWeight='bold'
              letterSpacing='tight'
              textAlign='center'
              width={900}
              margin='0 auto'
            >
              {hero.title}
            </Heading>
            <Text
              color={mode('gray.600', 'gray.400')}
              mt='8'
              fontSize='lg'
              textAlign='center'
              fontWeight='medium'
              width={780}
              mx='auto'
            >
              {hero.description}
            </Text>
            <Hide below='lg'>
              <>
                <Stack
                  direction={{
                    base: 'column',
                    md: 'row'
                  }}
                  spacing='4'
                  mt='12'
                  justifyContent='center'
                >
                  <Button
                    size='lg'
                    minW='210px'
                    colorScheme='blue'
                    height='14'
                    px='8'
                    onClick={() => actions.handleSignUp()}
                  >
                    {hero.freePlanButton.text}
                  </Button>
                  <Button
                    size='lg'
                    bg='white'
                    color='gray.700'
                    _hover={{
                      bg: 'gray.50'
                    }}
                    height='14'
                    px='8'
                    shadow='base'
                    leftIcon={<Box as={HiPlay} fontSize='2xl' />}
                    onClick={() => actions.handleDemo(hero.demoButton.link)}
                  >
                    {hero.demoButton.text}
                  </Button>
                </Stack>
                <Text
                  mt='8'
                  color={mode('gray.600', 'gray.400')}
                  textAlign='center'
                >
                  {hero.noCardText}
                </Text>
              </>
            </Hide>
          </Box>
          <Box
            pos='relative'
            w={{
              base: 'full'
            }}
            h={{
              base: 'auto',
              lg: '100%'
            }}
            paddingTop='50px'
          >
            <Img src={hero.image.url} alt={hero.title} />
            <Box w='100%' h='100%' />
          </Box>
          <Show below='lg'>
            <Stack
              direction={{
                base: 'column',
                md: 'row'
              }}
              spacing='4'
            >
              <Button
                size='lg'
                minW='210px'
                colorScheme='blue'
                height='14'
                px='8'
                onClick={() => actions.handleSignUp()}
              >
                {hero.freePlanButton.text}
              </Button>
              <Button
                size='lg'
                bg='white'
                color='gray.700'
                _hover={{
                  bg: 'gray.50'
                }}
                height='14'
                px='8'
                shadow='base'
                leftIcon={<Box as={HiPlay} fontSize='2xl' />}
                // onClick={openVideoModal}
                onClick={() => actions.handleDemo(hero.demoButton.link)}
              >
                {hero.demoButton.text}
              </Button>
            </Stack>
            <Text
              mt='4'
              color={mode('gray.600', 'gray.400')}
              textAlign='center'
            >
              {hero.noCardText}
            </Text>
          </Show>
        </Stack>
      </Box>
    </Box>
  )
}

export default Hero
